import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <html>
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Browse through our list of amazing products. Find the best deals and offers on a wide range of products."
        />
        <meta
          name="keywords"
          content="products, e-commerce, buy, deals, offers, sarees, fancy saree, variety heaven, latest saree, new saree"
        />
        <title>
          Variety Heaven | Products List | Latest Collection of Fancy Sarees
        </title>

        <meta
          property="og:title"
          content="Variety Heaven | Products List | Latest Collection of Fancy Sarees"
        />
        <meta
          property="og:description"
          content="Browse through our list of amazing products. Find the best deals and offers on a wide range of products."
        />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:url" content="https://varietyheaven.in/" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Variety Heaven | Products List | Latest Collection of Fancy Sarees"
        />
        <meta
          name="twitter:description"
          content="Browse through our list of amazing products. Find the best deals and offers on a wide range of products."
        />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
      </head>

      <body>
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </body>
    </html>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
